<div class="question-selection">
  <span class="block text-base my-5 font-medium"> {{tppName}}
    will access your information from your account(s) until:<br/> {{expiredTime | date: 'EEE MMM d y'}}</span>

  <span class="block text-base my-5 font-medium">The service provider need access to the following information from this account</span>
  <div *ngIf="isHsbcCmb">
    <app-drop-down-content [title]="'Your Account Details?'">
      <ol>
        <li>Any name by which you refer to this account</li>
        <li>Your account name, number and sort code</li>
        <li>Your account balance</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your Regular Payments?'">
      <ol>
        <li>Payee agreements you have set up</li>
        <li>Details of payees agreements you have set up</li>
        <li>Your standing orders</li>
        <li>Details of your standing orders</li>
        <li>Your Direct Debits</li>
        <li>Reoccurring and future dated payments</li>
        <li>Details of reoccurring and future dated payments</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your Account Transactions?'">
      <ol>
        <li>Your incoming transactions</li>
        <li>Your outgoing transactions</li>
        <li>Details of your incoming transactions</li>
        <li>Details of your outgoing transactions</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your Account Features and Benefits?'">
      <ol>
        <li>Product details - fees, charges, interest, benefits/rewards</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your Business Details?'">
      <ol>
        <li>Legal business name</li>
      </ol>
    </app-drop-down-content>
  </div>
  <div *ngIf="isHsbcWpb">
    <app-drop-down-content [title]="'Your account information?'">
      <ol>
        <li>Your account status and availability</li>
        <li>Your personal information which may include your full name, email address and phone number</li>
        <li>Your account's ID, name, number, type and currency</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your account balance?'">
      <ol>
        <li>Your account's balance type, balance credit/debit and date and time information</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Your account transactions?'">
      <ol>
        <li>Your account's credit/debit transactions</li>
        <li>Your account transaction's ID, transaction type, description and date and time information</li>
        <li>Your transaction's status, amount and currency</li>
        <li>Your foreign currency transaction amount, currency, exchange rate and date and time information</li>
      </ol>
    </app-drop-down-content>
  </div>


  <div *ngIf="isHaseCmb || isHaseWpb">
    <app-drop-down-content [title]="'Account Availability?'">
      <ol>
        <li>Verifying whether you have certain account type(s) by matching your account name, business registration number
          or mobile number with the bank’s record
        </li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Account Status?'">
      <ol>
        <li>Status of your account and general account information including account number, account type, currency, etc
        </li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Account Balance?'">
      <ol>
        <li>Your account balance information, including amount, currency, type of balance, balance date, etc</li>
      </ol>
    </app-drop-down-content>
    <app-drop-down-content [title]="'Account Transaction?'">
      <ol>
        <li>Incoming and outgoing transaction records, including amount, currency, date, etc. Please refer to your
          agreement with the Third Party Provider for more details on the scope of transaction records
        </li>
      </ol>
    </app-drop-down-content>
  </div>



</div>
