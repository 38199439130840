import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BusinessData} from "../model/model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GetSearchBusinessService {
  private searchBusinessURL = '/customer-auth/businesses/search';  // URL to web api
  constructor(private http: HttpClient) {
  }

  searchData(): Observable<BusinessData> {
    return this.http.get<BusinessData>(this.searchBusinessURL);
  }
}
