import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthenticateComponent} from "./pages/authenticate/authenticate.component";
import {CheckUsernameComponent} from "./pages/check-username/check-username.component";
import {SelectProfileComponent} from "./pages/select-profile/select-profile.component";
import {SelectAccountComponent} from "./pages/select-account/select-account.component";
import {InitComponent} from "./pages/init/init.component";
import {RedirectComponent} from "./pages/redirect/redirect.component";
import {ErrorComponent} from "./pages/error/error.component";

const routes: Routes = [
  {
    path: "authenticate", component: AuthenticateComponent,
  },
  {
    path: "check-username", component: CheckUsernameComponent,
  },
  {
    path: "select-profile", component: SelectProfileComponent,
  },
  {
    path: "select-account", component: SelectAccountComponent,
  },
  {
    path: "redirect", component: RedirectComponent,
  },
  {
    path: 'review', component: SelectAccountComponent
  },
  {
    path: "error", component: ErrorComponent,
  },
  {
    path: '', component: InitComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
