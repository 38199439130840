import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ParseJwtService {
  constructor() {}

  public static parse(token: string): any {
    const words = token.split('.');
    if(words.length < 2 )
      return null;
    const payload = token.split('.')[1];
    try {
        const jsonPayload = window.atob(payload);
        return JSON.parse(jsonPayload);
    }
    catch (e) {
        return null;
    }
  }
}
