import {Component, Inject, OnInit} from '@angular/core';
import {LocalDataService} from "../../../services/local-data.service";
import {DataKeyConstants} from "../../../model/model";
import {APP_CONFIG} from "../../../../main";

@Component({
  selector: 'app-question-selection',
  templateUrl: './question-selection.component.html',
  styleUrls: ['./question-selection.component.scss'],

})
export class QuestionSelectionComponent implements OnInit {

  tppName: string;
  expiredTime: string;
  isHsbcCmb: boolean;
  isHsbcWpb: boolean;
  isHaseCmb: boolean;
  isHaseWpb: boolean;

  constructor(
    private localDataService: LocalDataService, @Inject(APP_CONFIG) private appConfig: any) {

  }

  ngOnInit(): void {
    this.tppName = this.localDataService.getItem(DataKeyConstants.SESSION_DATA).tppInfo.clientName;
    this.expiredTime = this.getExpiredDate();
    this.isHsbcCmb = this.appConfig.bank === "HSBC" && this.appConfig.type === "CMB";
    this.isHsbcWpb = this.appConfig.bank === "HSBC" && this.appConfig.type === "WPB";
    this.isHaseCmb = this.appConfig.bank === "HASE" && this.appConfig.type === "CMB";
    this.isHaseWpb = this.appConfig.bank === "HASE" && this.appConfig.type === "CMB";
  }
  
  getExpiredDate() {
    const defaultExpiredDateTime = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 90).toDateString();
    const tppAccessExpiryDate =  this.localDataService.getItem(DataKeyConstants.SESSION_DATA).tppInfo.tppAccessExpiryDate || defaultExpiredDateTime;
    const expirationDateTime =  this.localDataService.getItem(DataKeyConstants.CONSENT_DATA).expirationDateTime || tppAccessExpiryDate;

    if(new Date(tppAccessExpiryDate) < new Date(expirationDateTime)) {
      return tppAccessExpiryDate;
    }
    return expirationDateTime;
  }


}
