import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocalDataService} from "../../services/local-data.service";
import {DataKeyConstants} from "../../model/model";
import {APP_CONFIG} from "../../../main";

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {
  username = '';

  checkoutForm = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(3)]),
    securityCode: new FormControl('', [Validators.required, Validators.minLength(3)])
  });
  isLoading = false;

  constructor(private router: Router,
              private localDataService: LocalDataService,
              @Inject(APP_CONFIG) private appConfig: any) {

  }

  ngOnInit(): void {
    this.username = this.localDataService.getItem(DataKeyConstants.USERNAME);
  }

  submit() {
    if (this.checkoutForm.valid) {
      const consentStatus = this.localDataService.getItem(DataKeyConstants.CONSENT_STATUS);
      if (consentStatus === 'Authorised') {
        this.router.navigate(["/review"]);
      } else {
        if (this.appConfig.type === "CMB") {
          this.router.navigate(["/select-profile"]);
        } else {
          this.router.navigate(["/select-account"]);
        }
      }

    }
  }

  changeUserName() {
    this.router.navigate(["/check-username"]);
  }
}
