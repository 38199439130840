import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-second-btn',
  templateUrl: './second-btn.component.html',
  styleUrls: ['./second-btn.component.scss']
})
export class SecondBtnComponent implements OnInit {

  @Input() text = '';
  @Output() click = new EventEmitter<string>();


  constructor() {
  }

  ngOnInit(): void {
  }

  btnClick() {
    this.click.emit("click");
  }
}
