<div class="tnc" (click)="clickTncEnv.emit()">
  <input type="checkbox" name="select-tnc" value="all" [checked]="isSelectTnc"/>
  <span>I agree the <a href="javascript:;" (click)="toggleShowPopupModal($event)">Terms & Conditions</a></span>
</div>

<div #popUpModal class="pop-up pop-up-show-animation" *ngIf="showTnc" (click)="toggleShowPopupModal($event)">
  <div class="pop-up-container">
    <div class="relative">
    <span class="absolute">
      <i class="icon close"></i>
    </span>
    </div>
    <div>
      <h2 class="text-base font-medium clear-both">Terms & Conditions</h2>
      <div class="w-full h-px	mt-3 mb-4" style="background-color: #333;"></div>
      <div *ngIf="isHsbcWpb">
        <span>
          By selecting 'Finish', you are consenting for us to share your account data,
          including transaction records for the past 60 days and the next 90 days or until the expiry date,
          whichever is earlier, to a third party service provider to use.
        </span>
        <br/><br/>
        <span>
          If you want to revoke your consent, you may do so via the Open banking page on HSBC HK Mobile Banking app or by visiting the website or mobile app of the third party service provider.
        </span>
        <br/><br/>
        <span>
          You can read more about 'Why aren't all accounts displayed?', 'Managing access' and 'Additional information' by tapping the help icon when selecting an account for Open banking.
        </span>
        <br/><br/>
        <span>
          If you have any other queries, please contact us at 2233 3000.
        </span>
      </div>
      <div *ngIf="!isHsbcWpb">
        <span>
          This is not an exact copy of the production user interface or PSU authentication journey.
          For the purposes of our sandbox journeys, we’ve implemented a standard AIS consent authorisation journey for all brands. This may differ from our production journeys
        </span>

      </div>
    </div>
  </div>
</div>
