import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";

import {Observable} from "rxjs";
import {Business, Permission} from "../model/model";

@Injectable({
  providedIn: 'root'
})
export class PutConsentService {
  private putConsentURL = '/customer-auth/consent';  // URL to web api
  constructor(private http: HttpClient) {
  }

  putConsentData(data: Business): Observable<any> {
    return this.http.put<any>(this.putConsentURL, data, {
      headers: {
        "requestType": "BUSINESS_CONTRACT"
      }
    });
  }

  putCompanyId(companyId: string): Observable<any> {
    return this.http.put<any>(this.putConsentURL, {
      companyId: companyId
    }, {
      headers: {
        requestType: "COMPANY_ID_UPDATE"
      }
    });
  }

  putPermissions(permissions: { permissions: Permission[] }) {
    return this.http.put<any>(this.putConsentURL, permissions);

  }
}
