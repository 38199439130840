import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ConsentData} from "../model/model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GetConsentDataService {
  private getConsentDataURL = '/customer-auth/data';  // URL to web api

  constructor(private http: HttpClient) {
  }

  getConsentData(): Observable<ConsentData> {
    return this.http.get<ConsentData>(this.getConsentDataURL);
  }
}
