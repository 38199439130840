export interface BusinessData {
  businessList: Array<Business>
}

export interface Business {
  userId: string | null,
  bibId: string,
  bcin?: string | null,
  businessName: string,
  companyId?: string
}

export interface AccountData {

  accounts: {
    account: Array<Account>,
    statusInfo?: any
  }
}


export interface Account {
  requestorAccountId: string
  scheme: {
    schemeName: string,
    identification: string
  },
  currency: string,
  accountType: string,
  accountSubType: string,
  nickName: string,
  balance: Array<string>,
  productSystemCode: string,
  businessName: string,
  notes: string,
  masterAccountIndicator?: boolean | null,
  controlAccountIdentification?: string | null,
  productName?: string | null
}


export interface AccountVo extends Account {
  usefulInfo: UsefulInfo,
  selected: boolean,
  index: number,
  isMaster: boolean,
  isSub: boolean,
  isOther: boolean,
  accountNumber: string,
  accountName: string,
  accountProductName: string
}

export enum AccountType {
  MASTER, SUB, OTHER
}

export interface UsefulInfo {
  accountType: string,
  accountSubType: string,
  type: string
}

export interface ConsentData {
  consentNickname?: string,
  combinedServiceIndicator?: boolean,
  recurringIndicator?: boolean,
  permissions?: Array<Permission>,
  accessExpiryDate?: string,
  expirationDateTime?: string,
  transactionFromDateTime?: string,
  transactionToDateTime?: string,
}

export interface Permission {
  permission: string,
  accounts: Array<PermissionAccount>
}

export interface PermissionAccount {

  accountSubType: string,
  accountType: string,
  currency: string,
  identification: string
  productSystemCode: string
  schemeName: string

}

export interface Session {
  tppInfo: {
    clientName: string,
    orgName: string,
    softwareOn_Behalf_Of_Org?: string,
    tpp_Role_Passport_Countries: Array<string>
    tppAccessExpiryDate: string
  }
}

export interface RedirectData {
  auth_code: string,
  redirect_uri: string,
  state: string,
  id_token: string
}

export abstract class DataKeyConstants {
  static readonly TOKEN = "TOKEN";
  static readonly CONSENT_STATUS = "CONSENT_STATUS98";
  static readonly CONSENT_DATA = "CONSENT_DATA";
  static readonly SESSION_DATA = "SESSION_DATA";
  static readonly USERNAME = "USERNAME";
  static readonly REDIRECT_URI = "REDIRECT_URI";
  static readonly REDIRECT_DATA = "REDIRECT_DATA";


}
