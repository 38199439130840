import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class PostSsoTokenService {
  private postSsoTokenURL = '/customer-auth/ssotoken';  // URL to web api
  constructor(private http: HttpClient) {
  }

  postSsoToken(ssoToken: string): Observable<any> {
    return this.http.post<any>(this.postSsoTokenURL, {"ssoToken": ssoToken});
  }
}
