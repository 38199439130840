<div class="wrapper flex flex-col">
  <div class="flex-auto">
    <div class="bg-white relative m-4 mb-20 content">
      <div>
        <div class="page-content">
          <div class="notification-container" *ngIf="error.length > 0">
            <div class="notification alert-error" id="_n_64297" tabindex="-1" role="alert">
              <div class="notification-content" rel="_n_64297">
                <span class="icon">{{this.errorMap.get(error)}}</span>
              </div>
            </div>
          </div>
          <div>
            <div class="min-h-[270px] page col-12 p-4 pb-5"  [style.min-height]="error.length >  0 ? 0 : 270">
              <div class="page-loading absolute top-[50%] left-[50%] -translate-x-1/2	-translate-y-1/2	" *ngIf="loading">
                <div class="global-spinner relative w-10 h-10 inline-block">
                <span class="absolute block w-[50%] h-full right-0 overflow-hidden origin-left animate-[spin_1.75s_linear_infinite]
                after:content-[''] after:block after:absolute after:box-border after:right-0 after:h-full after:w-[200%] after:animate-[rotate_1.75s_linear_infinite] after:border-t-black after:broder-l-balck after:rounded-full after:border-4 after: after:border-transparent"></span>
                </div>
              </div>
              <ng-content *ngIf="!loading"></ng-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  <app-footer></app-footer>
</div>
