import {Component, OnInit} from '@angular/core';
import {LocalDataService} from "../../services/local-data.service";
import {DataKeyConstants} from "../../model/model";

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(private localDataService: LocalDataService, private window: Window) {
  }

  ngOnInit(): void {
    const redirectData = this.localDataService.getItem(DataKeyConstants.REDIRECT_DATA);
    setTimeout(() => {
      this.window.location.href=`${redirectData.redirect_uri}#code=${encodeURIComponent(redirectData.auth_code)}&state=${encodeURIComponent(redirectData.state)}&id_token=${redirectData.id_token}`;
    }, 1000)
  }

}
