<app-container [error]="errNo" [loading]="initLoading">
  <h1 class="text-xl mb-5 font-family-HSBC-Next-Lt">Select a profile to continue:</h1>
  <div>
    <label id="profile-label" for="profile-selected" class="text-base font-medium leading-[1.2]" style="color: rgba(0,0,0,.9);">
      Profile
    </label>
    <div class="mx-0 mt-2 mb-4" id="profile-selected">
      <app-drop-down [data]="businessList"  (selectEnv)="selectItem($event)" ></app-drop-down>
    </div>
  </div>
  <div>
    <div class="mb-2">
      <label class="m-0 w-auto text-base"  for="one-company">Company</label>
    </div>
    <div class="single" id="one-company" role="status">{{businessName}}</div>
  </div>
  <div class="username-btns">
    <div class="mt-5 ">
      <app-button [text]="'Continue'" (clickEnv)="submit()" [isLoading]="isLoading"></app-button>
    </div>
  </div>
</app-container>
