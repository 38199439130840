import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/header/header.component';
import {FooterComponent} from './components/footer/footer.component';
import {CheckUsernameComponent} from './pages/check-username/check-username.component';
import {AuthenticateComponent} from './pages/authenticate/authenticate.component';
import {ButtonComponent} from './common/button/button.component';
import {SelectProfileComponent} from './pages/select-profile/select-profile.component';
import {DropDownComponent} from './common/drop-down/drop-down.component';
import {SelectAccountComponent} from './pages/select-account/select-account.component';
import {InitComponent} from './pages/init/init.component';
import {AddHeaderInterceptor} from "./utils/add-header-interceptor";
import {RedirectComponent} from './pages/redirect/redirect.component';
import {ContainerComponent} from './common/container/container.component';
import {ErrorComponent} from './pages/error/error.component';
import {SecondBtnComponent} from './common/second-btn/second-btn.component';
import {DropDownContentComponent} from './common/drop-down-content/drop-down-content.component';
import {QuestionSelectionComponent} from './pages/select-account/question-selection/question-selection.component';
import {TncComponent} from './pages/select-account/tnc/tnc.component';
import {AccountListComponent} from './pages/select-account/account-list/account-list.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CheckUsernameComponent,
    AuthenticateComponent,
    ButtonComponent,
    SelectProfileComponent,
    DropDownComponent,
    SelectAccountComponent,
    InitComponent,
    RedirectComponent,
    ContainerComponent,
    ErrorComponent,
    SecondBtnComponent,
    DropDownContentComponent,
    QuestionSelectionComponent,
    TncComponent,
    AccountListComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeaderInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
