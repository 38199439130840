import {Component, OnInit} from '@angular/core';
import {Business} from "../../model/model";
import {GetBusinessService} from "../../services/get-business.service";
import {Router} from "@angular/router";
import {PutConsentService} from "../../services/put-consent.service";
import {GetSearchBusinessService} from "../../services/get-search-business.service";
import {LocalDataService} from "../../services/local-data.service";
import {lastValueFrom} from "rxjs";

@Component({
  selector: 'app-select-profile',
  templateUrl: './select-profile.component.html',
  styleUrls: ['./select-profile.component.scss']
})
export class SelectProfileComponent implements OnInit {

  businessList: Array<Business> = [];
  currentBusiness: Business | null = null;
  businessName: string = "";
  isLoading = false;
  initLoading = true;
  errNo = '';


  constructor(private getBusinessService: GetBusinessService,
              private putBusinessService: PutConsentService,
              private getSearchBusinessService: GetSearchBusinessService,
              private localDataService: LocalDataService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initLoading = true;
    this.getBusinessService.getBusinessData().subscribe({
      next: (data) => {
        this.businessList = data.businessList.map(business => {
          return {
            ...business,
            bcin: business.bibId.substring(0, 18)
          }
        });
        this.initLoading = false;
      },
      error: () => {
        this.errNo = "SER_ERR_500";
        this.isLoading = false;
        this.initLoading = false;
      }
    })
  }


  submit() {
    this.router.navigate(["/select-account"]);
  }

  async selectItem($event: number) {
    this.isLoading = true;
    this.errNo = '';
    this.currentBusiness = this.businessList[$event];
    if (this.currentBusiness) {
      try {
        await lastValueFrom(this.putBusinessService.putConsentData(this.currentBusiness));
        const data = await lastValueFrom(this.getSearchBusinessService.searchData());
        if (data.businessList && data.businessList.length > 0) {
          const business = data.businessList[0];
          const companyId = business.companyId!;

          this.businessName = business.businessName;

          await lastValueFrom(this.putBusinessService.putCompanyId(companyId))

          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        this.errNo = "SER_ERR_500";
      }
    }
  }
}
