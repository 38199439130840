<div class="dropdown">
  <button class="btn" (click)="isShowList = !isShowList">
    <span>{{curValue}}</span>
    <i class="icon icon-chevron-down"></i>
  </button>
  <div class="dropdown-list block w-full" *ngIf="isShowList">
    <ul *ngFor="let bussiness of data;let i = index">
      <li (click)="selectValue(i)"
        class="list-group-item dropdown-item"
        role="option" tabindex="0" data-index="0"
        aria-selected="true">
        <span>{{bussiness.businessName}}</span>
        <span class="accessibleHidden" aria-label="selected">
          <span></span>
        </span>
      </li>

    </ul>
  </div>
  <input type="text" name="selector" hidden/>
</div>
