import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() buttonType = "submit";
  @Input() text = "submit";
  @Input() isLoading = false;
  @Input() isDisable = false;

  @Output() clickEnv = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }


  click() {
    this.clickEnv.emit("click")
  }
}
