import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-drop-down-content',
  templateUrl: './drop-down-content.component.html',
  styleUrls: ['./drop-down-content.component.scss']
})
export class DropDownContentComponent implements OnInit {
  isCollapsed = true;

  @ViewChild('collapsible') el:ElementRef;

  @Input() title = "";

  constructor() {
  }


  ngOnInit(): void {
  }

  click() {
    if(this.isCollapsed) {
      this.expandSection(this.el);
    } else {
      this.collapseSection(this.el);
    }

  }

   collapseSection(element:ElementRef) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.nativeElement.scrollHeight;

    // temporarily disable all css transitions
    var elementTransition = element.nativeElement.style.transition;
    element.nativeElement.style.transition = '';

    // on the next frame (as soon as the previous style change has taken effect),
    // explicitly set the element's height to its current pixel height, so we
    // aren't transitioning out of 'auto'
    requestAnimationFrame(function() {
      element.nativeElement.style.height = sectionHeight + 'px';
      element.nativeElement.style.transition = elementTransition;

      // on the next frame (as soon as the previous style change has taken effect),
      // have the element transition to height: 0
      requestAnimationFrame(function() {
        element.nativeElement.style.height = 0 + 'px';
      });
    });

     this.isCollapsed = true;
  }

   expandSection(element:ElementRef) {
    // get the height of the element's inner content, regardless of its actual size
    var sectionHeight = element.nativeElement.scrollHeight;

    // have the element transition to the height of its inner content
    element.nativeElement.style.height = sectionHeight + 'px';

    // when the next css transition finishes (which should be the one we just triggered)
    element.nativeElement.addEventListener('transitionend', ()=> {
      // remove this event listener so it only gets triggered once
      element.nativeElement.removeEventListener('transitionend', arguments.callee);

      // remove "height" from the element's inline styles, so it can return to its initial value
      element.nativeElement.style.height = null;
    });

    // mark the section as "currently not collapsed"
     this.isCollapsed = false;
  }

}
