<app-container [loading]="false">
  <div class="mb-5">
    <h1 class="font-family-HSBC-Next-Lt  mb-2 text-xl">
      <span>hello </span>
      <span class="inline-block truncate align-bottom	max-w-[75%]">{{username}}</span>
    </h1>
    <div class="leading-4 font-normal text-xs">
      <span>Not </span>
      <strong class="font-family-HSBC-Next-Md font-normal max-w-[60%] align-bottom inline-block truncate">
        {{username}}</strong>
      <span>?</span>
      <a href="javascript:;" class="underline" (click)="changeUserName()">Change user</a>
    </div>
  </div>
  <div>
    <form id="authenticateTokenForm" class="text-[#333] font-family-HSBC-Next-Rg " [formGroup]="checkoutForm">
      <div class="mb-4">
        <label class="block text-base	 mb-2 " for="password">
          Password
        </label>
        <input type="password" class="w-full h-10 text-sm border border-[#929292] leading-[1.3rem] px-[16px] pt-[11px] pb-[12px]"  name="password" id="password"  formControlName="password"/>
      </div>
      <div class="mb-4">
        <label class="block text-base mb-2" for="securityCode">
          Security Code
        </label>
        <input type="password" class="w-full h-10 text-sm border border-[#929292] leading-[1.3rem] px-[16px] pt-[11px] pb-[12px]" name="securityCode" id="securityCode"  formControlName="securityCode"/>
      </div>
      <div class="mt-5 ">
        <app-button  [text]="'Log on'" (clickEnv)="submit()" [isLoading]="isLoading" [isDisable]="!this.checkoutForm.valid" ></app-button>
      </div>
    </form>
  </div>
</app-container>
