import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Business} from "../../model/model";

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit{

  isShowList = false;
  curValue = "";

  private _data: Business[] = [];

  @Input() set data(data: Business[]) {
    this._data = data;
    this.change();
  }

  get data():  Business[] {
    return this._data;
  }

  @Output() selectEnv = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {

  }

  change () {
    if (this.data && this.data.length > 0) {
      this.selectValue(0)
    }
  }


  selectValue(index: number) {
    this.isShowList = !this.isShowList;
    this.curValue = this.data[index]?.businessName || "";
    this.selectEnv.emit(index);
  }

}
