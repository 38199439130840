<app-container [error]="errorNo" [loading]="false">
  <div class="logon-name-check-page">
    <div class="username-title title-content">
      <h1 class="text-xl font-family-HSBC-Next-Lt">
        Continue to manage consent for {{clientName}}?
      </h1>
    </div>
    <div class="description">
      Log on using your {{this.isWpb ? "" : 'Business'}} Internet Banking credentials to manage your consent for sharing data
      and information
      with {{clientName}}.
    </div>
    <form id="nameCheckForm" (ngSubmit)="submit()" [formGroup]="checkoutForm">
      <div class="form-group username-container">
        <label class="username-label" for="userName">
          Username
        </label>
        <input type="text" class="form-control" name="username" id="userName" formControlName="username"/>
      </div>
      <div class="mt-3.5">
        <div class="w-full text-center clearfix">
          <app-button [text]="'Continue'" [isLoading]="isLoading" [isDisable]="!this.checkoutForm.valid">
          </app-button>
          <a href="javascript:;" tabindex="-1" class="btn btn-link float-left no-underline	mb-0" (click)="back()">Back</a>
        </div>
      </div>
    </form>
  </div>
</app-container>
