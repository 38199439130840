<app-container [error]="errNo" [loading]="isInitLoading">
  <h1 class="text-xl mb-5 font-medium font-family-HSBC-Next-Lt	">Select accounts to authorize</h1>
  <div class="account-select">
    <app-account-list [accountList]="accountList" (selectItemEnv)="selectItem($event)" (selectAllEnv)="selectAll($event)" [isRefreshConsent]="isRefreshConsent" ></app-account-list>
  </div>

  <app-question-selection></app-question-selection>
  <app-tnc (clickTncEnv)="this.isSelectTnc =!isSelectTnc" [isSelectTnc]="isSelectTnc"></app-tnc>
  <div>
    <div class="mt-5 ">
      <app-button [text]="'Confirm'" [isDisable]="(this.selectSet.size === 0 && !isRefreshConsent)|| !isSelectTnc "
                  (clickEnv)="submit()"
                  [isLoading]="isLoading">
      </app-button>
    </div>
  </div>
</app-container>

