import {Inject, Injectable} from '@angular/core';
import {APP_CONFIG} from "../../main";
@Injectable({
  providedIn: 'root'
})
export class LocalDataService {

  model: { [index: string]: any } = {};


  constructor(@Inject(APP_CONFIG) private appConfig: any) {

  }

   setItem(key: string, value: any) {
    if (this.appConfig.environment === "local") {
      sessionStorage.setItem(key, JSON.stringify(value));
    } else {
      this.model[key] = value
    }
  }


   getItem(key: string) {
    if (this.appConfig.environment === "local") {
      const value = sessionStorage.getItem(key);
      return value && JSON.parse(value);
    } else {
      return this.model[key]
    }
  }


}
