<div class="share-info">
  <section class="getting-dropdown">
    <h2>
      <a href="javascript:void(0);" role="button"
         data-toggle="collapse"
         data-target="#how-to-authenticate-collapse-1"
         aria-expanded="true"
         aria-controls="how-to-authenticate-collapse-1"
         class="getting-dropdown-button" [class.collapsed]="isCollapsed">
        <span  class="block pr-4">{{title}}</span>
        <i class="icon icon-chevron-down-small" (click)="click()"></i>
      </a>
    </h2>
    <div #collapsible class="list-item-expandable-body ">
      <div>
        <ng-content></ng-content>
      </div>
    </div>
  </section>
</div>
