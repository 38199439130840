import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @Input() loading = true;
  @Input() error = '';
  errorMap: Map<string, string> = new Map([
    ["SER_ERR_500", "Service is temporarily unavailable. Please retry later."],
    ["LOG_ERR_400", "Logon details invalid. Please re-enter your username"]
  ]);

  constructor() {
  }


  ngOnInit(): void {
  }

}
