import { enableProdMode, InjectionToken } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

export const APP_CONFIG = new InjectionToken<any>('');

fetch('config/config.json')
  .then((res) => res.json())
  .then((config) => {
    if (config.production) {
      enableProdMode();
    }
    platformBrowserDynamic([{ provide: APP_CONFIG, useValue: config }])
      .bootstrapModule(AppModule)
      .catch((err) => console.error(err));
  });
