import {Component, Inject, OnInit} from '@angular/core';
import {GetAccountService} from '../../services/get-account.service';
import {
  AccountType,
  AccountVo,
  ConsentData, DataKeyConstants,
  Permission,
  PermissionAccount,
  UsefulInfo
} from '../../model/model';
import {LocalDataService} from "../../services/local-data.service";
import {PutConsentService} from "../../services/put-consent.service";
import {PostConfirmationService} from "../../services/post-confirmation.service";
import {Router} from "@angular/router";
import {accountProductName, formatAccountNumber, getAccountName, getAccountType} from "../../utils/account-list-helper";
import {lastValueFrom} from "rxjs";
import {APP_CONFIG} from "../../../main";

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss'],
})
export class SelectAccountComponent implements OnInit {
  accountList: Array<AccountVo> = [];
  selectSet: Set<number> = new Set<number>();

  consentData: ConsentData = {};


  isLoading = false;
  isInitLoading = true;


  errNo = '';
  isRefreshConsent = false;

  isSelectTnc = false;


  constructor(
    private router: Router,
    private localDataService: LocalDataService,
    private accountService: GetAccountService,
    private putBusinessService: PutConsentService,
    private postConfirmation: PostConfirmationService,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.consentData = this.localDataService.getItem(DataKeyConstants.CONSENT_DATA);
    this.isRefreshConsent = this.localDataService.getItem(DataKeyConstants.CONSENT_STATUS) === 'Authorised';
  }

  async ngOnInit() {
    await this.loadAccounts();

  }

  private async loadAccounts() {
    try {
      const data = await lastValueFrom(this.accountService.getAccountData());
      this.isInitLoading = false;

      this.accountList = data!.accounts.account.map((account, index) => {
        const usefulInfo: UsefulInfo = {
          accountType: account.notes.substring(18, 20),
          accountSubType: account.notes.substring(20, 23),
          type: account.notes.substring(15, 23).trim(),
        };
        const accountType = getAccountType(account, usefulInfo.accountType, usefulInfo.accountSubType);
        return {
          ...account,
          usefulInfo: usefulInfo,
          selected: false,
          index: index,
          isMaster: accountType === AccountType.MASTER,
          isSub: accountType === AccountType.SUB,
          isOther: accountType === AccountType.OTHER,
          accountNumber: formatAccountNumber(account.scheme.identification),
          accountName: getAccountName(account, usefulInfo, accountType),
          accountProductName: accountProductName(account, usefulInfo, accountType, this.appConfig)
        };
      })
    } catch (e) {
      this.isInitLoading = false;
      this.errNo = "SER_ERR_500";
    }
  }


  submit() {
    this.isLoading = true;

    if (this.isRefreshConsent) {
      this.postConfirmation.confirmation().subscribe(data => {
        this.isLoading = false;
        this.localDataService.setItem(DataKeyConstants.REDIRECT_DATA, data);
        this.router.navigate(["/redirect"]);
      }, err => {
        this.errNo = "SER_ERR_500";
        this.isLoading = false;
      });

    } else {
      const list: Array<PermissionAccount> = [];

      this.selectSet.forEach(i => {
        const account = this.accountList[i];
        list.push({
          accountSubType: account.accountSubType,
          accountType: account.accountType,
          currency: account.currency,
          identification: account.scheme.identification,
          productSystemCode: account.productSystemCode,
          schemeName: account.scheme.schemeName,
        })
      });

      const permissions = {permissions: <Permission[]>Array()};

      this.consentData!.permissions!.forEach(p => {
        p.accounts = list;
        permissions.permissions.push(p);
      });

      this.putBusinessService.putPermissions(permissions).subscribe(data => {
        this.postConfirmation.confirmation().subscribe(data => {
          this.isLoading = false;
          this.localDataService.setItem(DataKeyConstants.REDIRECT_DATA, data);
          this.router.navigate(["/redirect"]);
        }, err => {
          this.errNo = "SER_ERR_500";
          this.isLoading = false;
        })
      }, err => {
        this.errNo = "SER_ERR_500";
        this.isLoading = false;
      })
    }

  };

  selectAll($selectAll: boolean) {
    if ($selectAll) {
      for (const account of this.accountList) {
        if (!account.isMaster) {
          this.selectSet.add(account.index);
        }
      }
    } else {
      this.selectSet.clear();
    }
  }


  selectItem($index: number) {
    if (this.selectSet.has($index)) {
      this.selectSet.delete($index);
    } else {
      this.selectSet.add($index);
    }
  }


}
