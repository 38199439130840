import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AccountData} from "../model/model";

@Injectable({
  providedIn: 'root'
})
export class GetAccountService {

  private getAccountDataURL = '/customer-auth/accounts?withBalance=false';  // URL to web api
  constructor(private http: HttpClient) {
  }

  getAccountData(): Observable<AccountData> {
    return this.http.get<AccountData>(this.getAccountDataURL)
  }
}
