import {Component, ElementRef, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {APP_CONFIG} from "../../../../main";

@Component({
  selector: 'app-tnc',
  templateUrl: './tnc.component.html',
  styleUrls: ['./tnc.component.scss']
})
export class TncComponent implements OnInit {
  @ViewChild('popUpModal') el: ElementRef;

  @Input() isSelectTnc = false;

  @Output() clickTncEnv = new EventEmitter<boolean>();

  showTnc = false;
  isHsbcWpb: boolean;

  constructor(@Inject(APP_CONFIG) private appConfig: any) { }

  ngOnInit(): void {
    this.isHsbcWpb = this.appConfig.type === "WPB" && this.appConfig.bank === "HSBC";
  }

  toggleShowPopupModal(event: Event) {
    this.showTnc = !this.showTnc;
    if (this.showTnc) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    event.stopPropagation();
  }
}
