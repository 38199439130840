import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountVo} from "../../../model/model";

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {


  @Input()  accountList: Array<AccountVo>;
  @Input() isRefreshConsent = false;
  @Output() selectItemEnv  = new EventEmitter<number>();
  @Output() selectAllEnv  = new EventEmitter<boolean>();

  isSelectedAll = false;


  constructor() { }

  ngOnInit(): void {
  }

  selectAccount(account: AccountVo) {
    account.selected = !account.selected;
    this.selectItemEnv.emit(account.index);
  }

  selectAll() {
    this.isSelectedAll = !this.isSelectedAll;
    for(const account of this.accountList) {
      account.selected = this.isSelectedAll;
    }
    this.selectAllEnv.emit(this.isSelectedAll);
  }
}
