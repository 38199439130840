import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RedirectData} from "../model/model";

@Injectable({
  providedIn: 'root'
})
export class PostConfirmationService {
  private postConfirmation = '/customer-auth/confirmation';  // URL to web api

  constructor(private http: HttpClient) {
  }

  confirmation(): Observable<RedirectData> {
    return this.http.post<RedirectData>(this.postConfirmation, {
      authorisationStatus: true
    });
  }
}
