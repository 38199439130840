import {Account, AccountType, UsefulInfo} from "../model/model";

export function getAccountType(account: Account, accountType: string, accountSubType: string): AccountType {
  if (account.masterAccountIndicator === true && account.controlAccountIdentification == null) {
    return AccountType.MASTER;
  } else if (account.masterAccountIndicator === false && account.controlAccountIdentification) {
    return AccountType.SUB;
  } else if (account.masterAccountIndicator === false && account.controlAccountIdentification == null) {
    return AccountType.OTHER;
  }
  const SUB_ACCOUNT_TYPE = ['BVSAV', 'BVCUA', 'BVGLD', 'BVTMS', 'BVDBC', 'BVTSE', 'BVINV'];
  const MASTER_ACCOUNT_TYPE = 'BVMST';

  if (accountType + accountSubType === MASTER_ACCOUNT_TYPE) {
    return AccountType.MASTER;
  } else if (SUB_ACCOUNT_TYPE.includes(accountType + accountSubType)) {
    return AccountType.SUB;
  } else {
    return AccountType.OTHER;
  }
}


export function formatAccountNumber(number: string) {
  switch (number.length) {
    case 9:
      return `${number.substring(0, 3)}-${number.substring(3)}`;
    case 10:
      return `${number.substring(0, 3)}-${number.substring(3, 4)}-${number.substring(4)}`;
    case 12:
      return `${number.substring(0, 3)}-${number.substring(3, 9)}-${number.substring(9)}`;
    case 13:
      return `${number.substring(0, 3)}-${number.substring(3, 9)}`;
    case 16:
      return `${number.substring(0, 4)}-${number.substring(4, 8)}-${number.substring(8, 12)} -${number.substring(12)}`;
    default:
      return number;
  }
}

export function getTypeName(accountType: string | undefined | null): string {
  const map: Map<string, string> = new Map<string, string>([
    ["BVMST", "Business Integrated Account"],
    ["HKDBVMST", "Business Integrated Account"],
    ["HKDBVCUA", "Business Integrated HKD Current"],
    ["HKDBVSAV", "Business Integrated HKD Savings"],
    ["AUDBVSAV", "Business Integrated AUD Savings"],
    ["CADBVSAV", "Business Integrated CAD Savings"],
    ["CHFBVSAV", "Business Integrated CHF Savings"],
    ["CNYBVSAV", "Business Integrated RMB Savings"],
    ["EURBVSAV", "Business Integrated EUR Savings"],
    ["GBPBVSAV", "Business Integrated GBP Savings"],
    ["JPYBVSAV", "Business Integrated JPY Savings"],
    ["NZDBVSAV", "Business Integrated NZD Savings"],
    ["SGDBVSAV", "Business Integrated SGD Savings"],
    ["THBBVSAV", "Business Integrated THB Savings"],
    ["USDBVSAV", "Business Integrated USD Savings"],
    ["GLTBVGLD", "Business Integrated Gold"],
    ["CNYBVDBC", "Business Integrated RMB Savings"],
    ["CNYBVTSE", "Business Integrated RMB Trade Settlement Savings"],
    ["BVINV", "Business Integrated Securities Account"],
    ["HKDBVINV", "Business Integrated Securities Account"],
    ["BVTMS", "Business Integrated Time Deposit"],
    ["HKDBVTMS", "Business Integrated Time Deposit"],
    ["SUB_HKDBVCUA", "HKD Current"],
    ["SUB_HKDBVSAV", "HKD Savings"],
    ["SUB_AUDBVSAV", "AUD Savings"],
    ["SUB_CADBVSAV", "CAD Savings"],
    ["SUB_CHFBVSAV", "CHF Savings"],
    ["SUB_CNYBVSAV", "RMB Savings"],
    ["SUB_EURBVSAV", "EUR Savings"],
    ["SUB_GBPBVSAV", "GBP Savings"],
    ["SUB_JPYBVSAV", "JPY Savings"],
    ["SUB_NZDBVSAV", "NZD Savings"],
    ["SUB_SGDBVSAV", "SGD Savings"],
    ["SUB_THBBVSAV", "THB Savings"],
    ["SUB_USDBVSAV", "USD Savings"],
    ["SUB_GLTBVGLD", "Gold"],
    ["SUB_CNYBVDBC", "RMB Savings"],
    ["SUB_CNYBVTSE", "RMB Trade Settlement Savings"],
    ["SUB_BVINV", "Securities Account"],
    ["SUB_HKDBVINV", "Securities Account"],
    ["SUB_BVTMS", "Time Deposit"],
    ["SUB_HKDBVTMS", "Time Deposit"],
    ["AUDMCSAV", "AUD CombiNations Savings"],
    ["CADMCSAV", "CAD CombiNations Savings"],
    ["CHFMCSAV", "CHF CombiNations Savings"],
    ["DEMMCSAV", "DEM CombiNations Savings"],
    ["EURMCSAV", "EUR CombiNations Savings"],
    ["FRFMCSAV", "FRF CombiNations Savings"],
    ["GBPMCSAV", "GBP CombiNations Savings"],
    ["JPYMCSAV", "JPY CombiNations Savings"],
    ["NZDMCSAV", "NZD CombiNations Savings"],
    ["SGDMCSAV", "SGD CombiNations Savings"],
    ["THBMCSAV", "THB CombiNations Savings"],
    ["USDMCSAV", "USD CombiNations Savings"],
    ["AUDSSSSA", "AUD CombiNations Statement Savings Account"],
    ["CADSSSSA", "CAD CombiNations Statement Savings Account"],
    ["CHFSSSSA", "CHF CombiNations Statement Savings Account"],
    ["CNYSSSSA", "RMB Savings Account"],
    ["EURSSSSA", "EUR CombiNations Statement Savings Account"],
    ["GBPSSSSA", "GBP CombiNations Statement Savings Account"],
    ["HKDSSSSA", "HKD Statement Savings Account"],
    ["JPYSSSSA", "JPY CombiNations Statement Savings Account"],
    ["NZDSSSSA", "NZD CombiNations Statement Savings Account"],
    ["SGDSSSSA", "SGD CombiNations Statement Savings Account"],
    ["THBSSSSA", "THB CombiNations Statement Savings Account"],
    ["USDSSSSA", "USD CombiNations Statement Savings Account"],
    ["HKDCRSM1", "Business Card"],
    ["HKDCRVPB", "Platinum Business Card"],
    ["USDCRMCU", "World USD Corporate MasterCard"],
    ["HKDCRMCO", "World Corporate MasterCard"],
    ["HKDCRMPC", "Platinum Purchasing MasterCard"],
    ["HKDCRMB1", "Platinum Business MasterCard"],
    ["HKDCRMWB", "World Business MasterCard"],
    ["HKDCRMEB", "World Elite Business MasterCard"],
    ["CNYCRRC1", "RMB Business Card"],
    ["AUDSSCUA", "AUD Current Account"],
    ["ATSSSCUA", "ATS Current Account"],
    ["BEFSSCUA", "BEF Current Account"],
    ["BNDSSCUA", "BND Current Account"],
    ["CADSSCUA", "CAD Current Account"],
    ["CHFSSCUA", "CHF Current Account"],
    ["CNYSSCUA", "RMB Current Account"],
    ["DEMSSCUA", "DEM Current Account"],
    ["DKKSSCUA", "DKK Current Account"],
    ["ESPSSCUA", "ESP Current Account"],
    ["EURSSCUA", "EUR Current Account"],
    ["FIMSSCUA", "FIM Current Account"],
    ["FRFSSCUA", "FRF Current Account"],
    ["GBPSSCUA", "GBP Current Account"],
    ["HKDSSCUA", "HKD Current Account"],
    ["IDRSSCUA", "IDR Current Account"],
    ["IEPSSCUA", "IEP Current Account"],
    ["INRSSCUA", "INR Current Account"],
    ["ITLSSCUA", "ITL Current Account"],
    ["JPYSSCUA", "JPY Current Account"],
    ["NLGSSCUA", "NLG Current Account"],
    ["NOKSSCUA", "NOK Current Account"],
    ["NZDSSCUA", "NZD Current Account"],
    ["PHPSSCUA", "PHP Current Account"],
    ["PKRSSCUA", "PKR Current Account"],
    ["PTESSCUA", "PTE Current Account"],
    ["SEKSSCUA", "SEK Current Account"],
    ["SGDSSCUA", "SGD Current Account"],
    ["THBSSCUA", "THB Current Account"],
    ["USDSSCUA", "USD Current Account"],
    ["ZARSSCUA", "ZAR Current Account"],
    ["HKDSSSAV", "HKD Savings Account"],
    ["USDSSSAV", "USD Savings Account"],
    ["HKDSSEXP", "Export Account"],
    ["HKDSSIMP", "Import Account"],
    ["HKDSSINV", "Securities Account"],
    ["HKDSSSEC", "Securities Account"],
    ["HKDSSTMS", "Time Deposit"],
    ["SSEXP", "Export Account"],
    ["SSIMP", "Import Account"],
    ["SSINV", "Securities Account"],
    ["SSSEC", "Securities Account"],
    ["SSTMS", "Time Deposit"],
    ["CNYSSDBC", "RMB Savings Account"],
    ["CNYSSTSE", "RMB Trade Settlement Savings Account"],
    ["GLTSSGLD", "Statement Gold Account"],
    ["HKDILBDG", "Bridging Loan"],
    ["HKDILBPF", "Business Property Financing"],
    ["HKDILFLR", "Flat Rate Loan"],
    ["HKDILHBR", "HIBOR Loan"],
    ["HKDILIND", "Ind/Comm Loan"],
    ["HKDILBPL", "Commercial Mortgage Loan"],
    ["HKDILLAN", "HKD Loan"],
    ["HKDILMRT", "Mortgage Loan"],
    ["HKDILPTL", "Profits Tax Loan"],
    ["HKDILSBL", "Business Instalment Loan"],
    ["HKDILSYD", "Syndication Loan"],
    ["HKDILTRM", "HKD Term Loan"],
    ["ILBDG", "Bridging Loan"],
    ["ILBPL", "Commercial Mortgage Loan"],
    ["ILFLR", "Flat Rate Loan"],
    ["ILHBR", "HIBOR Loan"],
    ["ILIND", "Ind/Comm Loan"],
    ["ILMRT", "Mortgage Loan"],
    ["ILPTL", "Profits Tax Loan"],
    ["ILSBL", "Business Instalment Loan"],
    ["ILSYD", "Syndication Loan"]
  ]);


  if (accountType) {
    return map.get(accountType) || "";
  } else {
    return "";
  }
}

export function getAccountName(account: Account, usefulInfo: UsefulInfo, accountType: AccountType) {
  if(account.productName) {
    return account.productName
  }
  if(accountType == AccountType.SUB) {
    return getTypeName("SUB_" + usefulInfo.type);
  }
  return getTypeName(usefulInfo.type);
}

export function accountProductName(account: Account, usefulInfo: UsefulInfo, accountType: AccountType, appConfig: any) {
  if (accountType == AccountType.SUB && appConfig.type === "CMB") {
    return getTypeName(usefulInfo.type);
  }
  return  "";
}

