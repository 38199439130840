import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LocalDataService} from "../../services/local-data.service";
import {GetConsentDataService} from "../../services/get-consent-data.service";
import {zip} from "rxjs";
import {GetSessionService} from "../../services/get-session.service";
import {ParseJwtService} from "../../services/parse-jwt.service";
import {DataKeyConstants} from "../../model/model";

@Component({
  selector: 'app-init',
  templateUrl: './init.component.html',
  styleUrls: ['./init.component.scss']
})
export class InitComponent implements OnInit {

  errNo = '';
  isLoading = true;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private consentDataService: GetConsentDataService,
              private sessionService: GetSessionService,
              private localDataService: LocalDataService) {
  }

  ngOnInit(): void {
    this.initData();
  }

  async initData() {

    this.activatedRoute.queryParams.subscribe(params => {
      const redirectJwt = params['redirectJwt'];

      if (!redirectJwt) {
        this.isLoading = false;
        this.router.navigate(["error"])
      }
      const jwtObj = ParseJwtService.parse(redirectJwt);
      const redirectUri = jwtObj['redirectUri'];
      const consentStatus = jwtObj["consentStatus"];


      this.localDataService.setItem(DataKeyConstants.CONSENT_STATUS, consentStatus);
      this.localDataService.setItem(DataKeyConstants.REDIRECT_URI, redirectUri);
      this.localDataService.setItem(DataKeyConstants.TOKEN, redirectJwt);
      zip(this.sessionService.getSession(), this.consentDataService.getConsentData())
        .subscribe({
          next: (data) => {
            this.localDataService.setItem(DataKeyConstants.SESSION_DATA, data[0]);
            this.localDataService.setItem(DataKeyConstants.CONSENT_DATA, data[1]);

            this.router.navigate(["/check-username"]);
          },
          error: () => {
            this.isLoading = false;
            this.router.navigate(["/error"])
          }
        });
    })

  }
}
