import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Session} from "../model/model";

@Injectable({
  providedIn: 'root'
})
export class GetSessionService {
  private  getSessionURL = '/customer-auth/sessions';  // URL to web api

  constructor(private http: HttpClient) { }

  getSession(): Observable<Session> {
    return this.http.get<Session>(this.getSessionURL);
  }
}
