import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalDataService} from "../services/local-data.service";
import {Injectable} from "@angular/core";
import {DataKeyConstants} from "../model/model";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {

  constructor(private localDataService: LocalDataService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    const authorization = `Bearer ${this.localDataService.getItem(DataKeyConstants.TOKEN)}`;
    const clonedRequest = req.clone({ headers: req.headers.append('Authorization', authorization) });

    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest);
  }
}
