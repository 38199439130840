import {Component, OnInit} from '@angular/core';
import {LocalDataService} from "../../services/local-data.service";
import {DataKeyConstants} from "../../model/model";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private localDataService: LocalDataService, private window: Window) {
  }

  ngOnInit(): void {
  }

  back() {
    this.window.location.href = this.localDataService.getItem(DataKeyConstants.REDIRECT_URI);
  }
}
