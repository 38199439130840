import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LocalDataService} from "../../services/local-data.service";
import {PostSsoTokenService} from "../../services/post-sso-token.service";
import {HttpErrorResponse} from "@angular/common/http";
import { lastValueFrom } from 'rxjs';
import {DataKeyConstants} from "../../model/model";
import {APP_CONFIG} from "../../../main";

@Component({
  selector: 'app-login',
  templateUrl: './check-username.component.html',
  styleUrls: ['./check-username.component.scss']
})
export class CheckUsernameComponent implements OnInit {
  checkoutForm = new FormGroup({
    username: new FormControl('', [Validators.required, Validators.minLength(3)]),
  });
  isLoading = false;
  clientName = "";
  errorNo = '';
  public isWpb: boolean;

  constructor(private router: Router,
              private postSSoService: PostSsoTokenService,
              private localDataService: LocalDataService,
              private window: Window,
              @Inject(APP_CONFIG) private appConfig: any) {
      this.isWpb = appConfig.type === "WPB";
  }

  ngOnInit(): void {
    if (!this.localDataService.getItem(DataKeyConstants.SESSION_DATA) ||
      !this.localDataService.getItem(DataKeyConstants.SESSION_DATA).tppInfo) {
      this.router.navigate(["/error"]);
      return;
    }
    this.clientName = this.localDataService.getItem(DataKeyConstants.SESSION_DATA).tppInfo.clientName;

  }

  async submit() {
    if (this.checkoutForm.valid && this.username?.value) {
      this.isLoading = true;
      const username: string = this.username?.value;
      this.localDataService.setItem(DataKeyConstants.USERNAME, username);
      try {
        await lastValueFrom(this.postSSoService.postSsoToken(username));
        this.isLoading = false;
        this.router.navigate(["/authenticate"]);
      } catch (err) {
        this.checkoutForm.controls.username.setValue('');
        if (err instanceof HttpErrorResponse && err?.error?.code === 400) {
          this.errorNo = "LOG_ERR_400";
        } else {
          this.errorNo = "SER_ERR_500";
        }
        this.isLoading = false;
      }
    }
  }

  get username() {
    return this.checkoutForm.get("username");
  }

  back() {
    this.window.location.href = this.localDataService.getItem(DataKeyConstants.REDIRECT_URI);
  }
}


