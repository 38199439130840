<table class="account-selection w-full text-left border-collapse border-spacing-px	">
  <tr class="account-title-row h-10 py-[10px] px-[16px]" (click)="selectAll()">
    <th class="row-left py-[10px] px-[16px]" *ngIf="!isRefreshConsent">
      <input id="select-all-id" class="text-sm" type="checkbox" name="select-all" value="all"
             [checked]="isSelectedAll"/>
      <label for="select-all-id" aria-label="select all account"></label>
    </th>
    <th class="py-3	pr-4 align-baseline" [class.pl-4]="isRefreshConsent">
      <div class="input-label text-sm	font-family-HSBC-Next-Md">Account</div>
    </th>
  </tr>
  <tbody>
  <ng-container *ngFor="let account of accountList;let index = index">

    <tr class="parent-account" *ngIf="account.isOther" (click)="selectAccount(account)">
      <td class="row-left py-[10px] px-[16px]" *ngIf="!isRefreshConsent">
        <input id="select-account-{{index}}-id" class="check-account sub-account-input" type="checkbox"
               name="select-account-{{index}}" value="{{index}}" [checked]="account.selected"/>
        <label for="select-account-{{index}}-id"></label>
      </td>
      <td class="account" [class.pl-4]="isRefreshConsent">
        <div id="account-{{index}}-id">

          <div class="account-name">{{account.accountName}}</div>
          <div class="account-number little-font">
            <span class="no-wrap">{{account.accountNumber}}</span>
            <span *ngIf="account.accountProductName"> - </span>
            <span *ngIf="account.accountProductName">{{account.accountProductName}}</span>
          </div>
        </div>
      </td>
    </tr>
    <tr class="parent-account master" *ngIf="account.isMaster" (click)="selectAccount(account)">
      <td class="account" colspan="2" [class.pl-4]="isRefreshConsent">
        <div id="account-{{index}}-id">

          <div class="account-name">{{account.accountName}}</div>

          <div class="account-number little-font">
            <span class="no-wrap">{{account.accountNumber }}</span>
            <span *ngIf="account.accountProductName"> - </span>
            <span *ngIf="account.accountProductName">{{account.accountProductName}}</span>
          </div>
        </div>
      </td>
    </tr>

    <tr class="child-account" *ngIf="account.isSub" (click)="selectAccount(account)">
      <td class="row-left py-[10px] px-[16px]" *ngIf="!isRefreshConsent">
        <input id="select-account-{{index}}-id" class="check-account sub-account-input" type="checkbox"
               name="select-account-{{index}}" value="{{index}}" [checked]="account.selected"/>
        <label for="select-account-{{index}}-id"></label>
      </td>
      <td class="account" [class.pl-4]="isRefreshConsent">
        <div id="account-{{index}}-id">
          <div class="account-name">{{account.accountName}}</div>
          <div class="little-font">
            <span class="no-wrap">{{account.accountNumber}}</span>
            <span *ngIf="account.accountProductName"> - </span>
            <span *ngIf="account.accountProductName">{{account.accountProductName}}</span>
          </div>
        </div>
      </td>
    </tr>

  </ng-container>
  </tbody>
</table>
